import React from 'react'
import { motion } from "framer-motion"

import * as styles from '../styles/tagslist.module.scss'

import { createLinkStyle, createTagsNameMap } from './functions/createconst'
import { graphql, useStaticQuery } from 'gatsby'

const TagsList = ({istop}) =>{

    const { taggroup } = useStaticQuery(
        graphql`
            query {
                taggroup: allMdx(limit: 2000) {
                    group(field: frontmatter___tags) {
                        fieldValue
                        totalCount
                    }
                }
            }
        `
    )

    let items = []

    const tagnamemap = createTagsNameMap()
    const LinkStyle = createLinkStyle()

    const hoverVariants = {
        rest:{
            backgroundColor: "#3d4043",
            borderColor: "#555a5e",
        },
        hover:{
            backgroundColor: "#51a44c",
            borderColor: "#2f5e2b",
        },
    }
    

    for(let i = 0; i < taggroup.group.length; i++){
        let slug = `/tags/${taggroup.group[i].fieldValue}`;
        let tag = tagnamemap[taggroup.group[i].fieldValue]

        items.push(
            <div className={styles.linkwrapper} key={slug}>
                <a href = {slug} style={LinkStyle}>
                    <motion.div className={styles.tag}
                        whileHover = "hover"
                        variants = {hoverVariants}
                    >
                        {tag}
                    </motion.div>
                </a>
            </div>
        )
    }


    return (
        <div className={istop ? styles.background : styles.whitebackground}>
            <div className = {styles.tagslistwrapper}>
                {items}
            </div>
        </div>
    )
}

export default TagsList